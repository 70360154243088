<template>
  <div id="transaction-list">
    <v-card>
      <v-card-title>
        {{ t('tooltip.filters') }}
      </v-card-title>

      <v-row class="px-2 ma-0 text-center">
        <v-col
          cols="12"
          sm="3"
        >
          <v-select
            v-model="statusFilter"
            :items="statusOptions"
            item-text="text"
            item-value="value"
            single-line
            outlined
            dense
            hide-details
            clearable
            :placeholder="t('select.status')"
            @change="updateFilter('views-order-history-list', 'status', statusFilter)"
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          sm="2"
        >
          <v-menu
            v-model="modalFrom"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedDateFrom"
                :placeholder="t('transactions.from_date')"
                :prepend-icon="icons.mdiCalendar"
                outlined
                readonly
                dense
                clearable
                hide-details
                v-bind="attrs"
                v-on="on"
                @click:clear="updateFilter('views-order-history-list', 'dateFrom', null)"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateFrom"
              color="secondary"
              :max="dateTo ? dateTo : maxDate"
              @input="modalFrom = false"
              @change="updateFilter('views-order-history-list', 'dateFrom', dateFrom)"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          cols="12"
          sm="2"
        >
          <v-menu
            v-model="modalTo"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedDateTo"
                :placeholder="t('transactions.to_date')"
                :prepend-icon="icons.mdiCalendar"
                outlined
                readonly
                dense
                clearable
                hide-details
                v-bind="attrs"
                v-on="on"
                @click:clear="updateFilter('views-order-history-list', 'dateTo', null)"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateTo"
              color="secondary"
              :max="maxDate"
              :min="dateFrom"
              @input="modalTo = false"
              @change="updateFilter('views-order-history-list', 'dateTo', dateTo)"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          v-if="userData && userData.role === 'A'"
          cols="12"
          sm="3"
        >
          <v-select
            v-model="purchaseSiteFilter"
            :items="purchaseSites"
            item-text="text"
            item-value="value"
            single-line
            outlined
            dense
            hide-details
            :placeholder="t('transactions.purchase_site')"
            @change="updateFilter('views-order-history-list', 'purchaseSite', purchaseSiteFilter)"
          ></v-select>
        </v-col>
      </v-row>
      <v-divider />

      <v-row class="px-2 ma-0 text-center">
        <v-col
          cols="12"
          sm="12"
          md="3"
        >
          <v-text-field
            v-model="searchQuery"
            :placeholder="t('search')"
            outlined
            hide-details
            dense
          >
          </v-text-field>
        </v-col>

        <v-spacer />

        <v-col
          v-if="userData && userData.role === 'A' && hasPermission(6)"
          cols="12"
          sm="2"
        >
          <v-btn
            color="secondary"
            block
            @click="exportExcel('/transaction/file/')"
          >
            <img
              :src="icons.export"
              height="20px"
              width="20px"
              alt="export"
              attrs="export"
              style="margin-right: 5px; background-position: center center"
            />
            {{ t('tooltip.export') }}
          </v-btn>
        </v-col>

        <v-col
          v-if="hasPermission(83)"
          cols="12"
          sm="3"
        >
          <v-btn
            class="font-weight-regular"
            color="secondary"
            block
            @click="isOpenModal = true"
          >
            <v-icon
              size="20"
              class="mr-2"
              style="color: #FFFFFF"
            >
              {{ icons.mdiPrinter }}
            </v-icon>
            {{ t('transactions.monthly_report').toUpperCase() }}
          </v-btn>
        </v-col>

        <v-col
          v-if="hasPermission(7)"
          cols="12"
          sm="2"
        >
          <v-btn
            color="secondary"
            block
            :disabled="transactionSelectedLength === 0"
            @click="addPayment"
          >
            <v-icon>
              {{ icons.mdiCurrencyUsd }}
            </v-icon>
            {{ t("transactions.pay_now") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-divider />

      <v-data-table
        :headers="computedTableColumns"
        :items="transactionListTable"
        :loading="loading"
        class="text-no-wrap"
        :loading-text="t('table.loading_text')"
        :no-data-text="t('table.no_data')"
        :header-props="{sortByText: t('table.sort_by')}"
        :footer-props="{itemsPerPageText: t('table.item_page'), 'items-per-page-options':[10, 15, 25, 50, -1], 'items-per-page-all-text': $t('tooltip.all')}"
        :items-per-page="-1"
        :search.sync="searchQuery"
        :sort-desc.sync="descSort"
        :options.sync="options"
        :server-items-length="totalTransactionListTable"
      >
        <template v-slot:header.paid="{ header }">
          <v-checkbox
            class="ma-0 pa-0 text-sm"
            color="red"
            hide-details
            @change="(e) =>selectTransaction(header, true, e)"
          >
            <template v-slot:label>
              <span
                style="
                  user-select: none;
                  font-size: 0.75rem;
                "
              >
                {{ header.text }}
              </span>
            </template>
          </v-checkbox>
        </template>

        <template v-slot:item.paid="{ item }">
          <v-checkbox
            v-if="item.status === 'N'"
            v-model="item.paid"
            color="red"
            hide-details
            class="ma-0 pa-0"
            @change="selectTransaction(item)"
          ></v-checkbox>
          <v-icon
            v-else
            size="18"
            color="success"
          >
            {{ icons.mdiCheck }}
          </v-icon>
        </template>

        <template #[`item.id`]="{item}">
          <router-link
            class="font-weight-medium text-decoration-none"
            :style="$vuetify.theme.dark ? 'color: #e7e3fcad' : '#3b0000de' "
            :to="{ name: 'views-order-history-preview', params: { id: item.id } }"
          >
            #{{ item.id }}
          </router-link>
        </template>

        <template #[`item.transaction_date_short`]="{item}">
          {{ formatStdDate(item.transaction_date_short, 'datelist', configObjFacility.is12Hour, $i18n.locale) }}
        </template>

        <template #[`item.group_name`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="resolveClientAvatarVariant(item.status)"
              :class="`v-avatar-light-bg ${resolveClientAvatarVariant(item.status)}--text`"
              size="30"
            >
              <v-img
                v-if="item.group_avatar"
                :src="item.group_avatar"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.group_name) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <span class="d-block text--primary  font-weight-semibold text-truncate">{{ item.group_name }}</span>
              <span
                v-if="item.user"
                class="text-xs"
              >{{ item.user.username }}</span>
            </div>
          </div>
        </template>

        <template #[`item.video.video_date`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <span class="d-block text--primary  font-weight-semibold text-truncate">{{ formatStdDate(item.video.video_date, 'datelist', configObjFacility.is12Hour, $i18n.locale) }}</span>
              <span class="text-xs">{{ t('videos.video') }} ID: {{ item.video.id }}</span>
            </div>
          </div>
        </template>

        <template #[`item.video.start_time`]="{item}">
          {{ formatStdDate(item.video.start_time, 'time', configObjFacility.is12Hour, $i18n.locale) }}
        </template>

        <template #[`item.subtotal`]="{item}">
          <span class="text-no-wrap">{{ formatCurrency(item.subtotal) }}</span>
        </template>

        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="resolveClientAvatarVariant(item.status)"
            :class="`${resolveClientAvatarVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ resolveStatusText(item.status) }}
          </v-chip>
        </template>

        <template #[`item.actions`]="{item}">
          <div class="d-flex align-center justify-center">
            <v-tooltip
              v-if="hasPermission(4)"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  color="info"
                  v-bind="attrs"
                  :to="{ name: 'views-order-history-preview', params: { id: item.id, dataParams: item, pageParams: options, filterParams: filters } }"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiEyeOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ t('tooltip.view_detail') }}</span>
            </v-tooltip>

            <v-tooltip
              v-if="userData.role === 'A' && item.can_cancel && item.video && hasPermission(5)"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  color="secondary"
                  v-bind="attrs"
                  v-on="on"
                  @click="cancelVideo(item)"
                >
                  <v-icon size="18">
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ t('tooltip.cancel') }} {{ t('videos.video') }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>

      <order-history-sidebar-add-payment
        v-model="isAddPaymentSidebarActive"
        :transaction-selected="transactionSelected"
        :amount-transaction-selected="amountTransactionSelected"
        :group-id="groupId"
        @reload="onReload"
        @clearTransaction="clearTransactionSelected"
      ></order-history-sidebar-add-payment>

      <v-dialog
        v-model="isOpenModal"
        max-width="600px"
      >
        <v-card
          class="pa-3"
          rounded="10"
          style="width: 100%"
        >
          <v-form ref="form">
            <v-app-bar
              flat
              color="rgba(0, 0, 0, 0)"
              dense
              class="px-4"
            >
              <v-toolbar-title class="text-h6 white--text pl-0">
                {{ t('transactions.monthly_report') }}
              </v-toolbar-title>
            </v-app-bar>

            <v-card-text class="pa-0 mt-5 mb-7 justify-center d-flex flex-column">
              <v-row class="justify-center align-center px-9 pt-3">
                <v-col
                  v-if="userData.role === 'A'"
                  cols="12"
                  md="6"
                  class="py-0"
                >
                  <v-select
                    v-model="monthlyReport.group_id"
                    :label="t('organizations.organization')"
                    :placeholder="t('organizations.organization')"
                    :items="groupsOptions"
                    item-text="name"
                    item-value="id"
                    outlined
                    clearable
                    dense
                    :rules="[required]"
                    @change="onChangeGroup"
                  ></v-select>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  class="py-0"
                >
                  <v-menu
                    v-model="modalMonth"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="monthlyReport.date"
                        :label="`${t('time.year')}/${t('time.month')}`"
                        :placeholder="`${t('time.year')}/${t('time.month')}`"
                        :prepend-icon="icons.mdiCalendar"
                        outlined
                        readonly
                        dense
                        :rules="[required]"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="monthlyReport.date"
                      type="month"
                      color="primary"
                      :max="maxDate"
                      :min="minDateMont"
                      no-title
                      @input="modalMonth = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions class="d-flex flex-column justify-center">
              <v-row class="d-flex justify-center align-center">
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  class="py-3 py-sm-0 px-0 px-sm-2"
                >
                  <v-btn
                    class="text-xs"
                    color="secondary"
                    dark
                    rounded
                    block
                    width="100%"
                    @click="onGenerateReport"
                  >
                    {{ t('tooltip.print') }}
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  class="py-3 py-sm-0 px-0 px-sm-2"
                >
                  <v-btn
                    class="text-xs"
                    color="lightGray"
                    dark
                    rounded
                    outlined
                    block
                    width="100%"
                    @click="isOpenModal = false"
                  >
                    {{ t('tooltip.close') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>

      <vue-html2pdf
        ref="html2PdfReport"
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :enable-links="true"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="Payment history monthly report"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        :html-to-pdf-options="{
          html2canvas: {
            scrollX: 0,
            scrollY: 0,
          },
        }"
        @beforeDownload="beforeDownload()"
        @hasDownloaded="hasDownloaded()"
      >
        <section
          slot="pdf-content"
          style="background-color: #ffffff; width: 100%; height: 100%"
        >
          <payment-history-monthly-report
            :data-params="computedPaymentDataMonthly"
            :has-transaction-video="hasTransactionVideoMonthly"
            :group="groupName"
            :logo="groupLogo"
            :year="year"
            :month="month"
          ></payment-history-monthly-report>
        </section>
      </vue-html2pdf>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
/* eslint-disable no-param-reassign */
/* eslint-disable operator-linebreak */
import {
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiDownloadOutline,
  mdiPencilOutline,
  mdiContentCopy,
  mdiCurrencyUsd,
  mdiCheck,
  mdiClose,
  mdiMicrosoftExcel,
  mdiFileExcelOutline,
  mdiPlus,
  mdiPrinter,
} from '@mdi/js'

import { onMounted, ref, computed, getCurrentInstance } from '@vue/composition-api'
import { getGroups, cancelVideoTransaction, getTransactionsMonthly } from '@api'
import { avatarText } from '@core/utils/filter'
import { success, error, info } from '@core/utils/toasted'
import { formatCurrency, useRouter, formatStdDate } from '@core/utils'
import usePermissions from '@core/utils/usePermissions'
import exportExcel from '@core/utils/useExportExcel'
import { required } from '@core/utils/validation'
import Swal from 'sweetalert2'

// components
import VueHtml2pdf from 'vue-html2pdf'
import PaymentHistoryMonthlyReport from '@/templates/reports/PaymentHistoryMonthlyReport.vue'
import OrderHistorySidebarAddPayment from '../OrderHistorySidebarAddPayment.vue'

// composition function
import useOrderHistoryList from './useOrderHistoryList'

export default {
  components: {
    OrderHistorySidebarAddPayment,
    VueHtml2pdf,
    PaymentHistoryMonthlyReport,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { hasPermission } = usePermissions()
    const { route } = useRouter()

    const {
      transactionListTable,
      filters,
      searchQuery,
      options,
      descSort,
      userData,
      statusFilter,
      totalTransactionListTable,
      loading,
      computedTableColumns,
      columnsExcel,
      columnsExcelCSV,
      minDate,
      maxDate,
      dateFrom,
      dateTo,
      transactionSelected,
      transactionSelectedItem,
      amountTransactionSelected,
      transactionSelectedLength,
      purchaseSiteFilter,
      purchaseSites,
      configObjFacility,

      t,
      resolveClientAvatarVariant,
      fetchAllTransactions,
      fetchPagedTransactions,
      resolveStatusText,
      clearTransactionSelected,
      setFilters,
      setOptions,
      updateFilter,
      getFilterByModule,
    } = useOrderHistoryList()

    const isOpenModal = ref(false)
    const isAddPaymentSidebarActive = ref(false)
    const groupId = ref(null)

    const dateNow = ref(new Date().toISOString().substring(0, 10))
    const downloadFlag = ref(true)
    const timerDownload = ref(null)
    const modalFrom = ref(false)
    const modalTo = ref(false)

    const form = ref(null)
    const monthlyReport = ref({
      group_id: userData.value.role !== 'A' ? userData.value.group_id : null,
      date: null,
    })
    const modalYear = ref(false)
    const modalMonth = ref(false)
    const html2PdfReport = ref(null)
    const initMonthlyData = ref({
      commisions: null,
      refunds: null,
      subtotal: null,
      total: null,
      total_time: null,
      transactions: [],
    })
    const computedDateFrom = computed(() => (dateFrom.value ? formatStdDate(dateFrom.value, 'date', configObjFacility.value.is12Hour, vm.$i18n.locale) : null))
    const computedDateTo = computed(() => (dateTo.value ? formatStdDate(dateTo.value, 'date', configObjFacility.value.is12Hour, vm.$i18n.locale) : null))
    const paymentDataMonthly = ref(initMonthlyData.value)
    const computedPaymentDataMonthly = computed(() => paymentDataMonthly.value)
    const hasTransactionVideoMonthly = computed(() => {
      if (paymentDataMonthly.value) return paymentDataMonthly.value.transactions.length > 0

      return false
    })
    const groupName = ref('')
    const groupLogo = ref('')
    const year = ref('')
    const month = ref('')

    const minDateMont = ref(new Date('2021-01-31').toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }))

    const footerProps = {
      'items-per-page-text': t('table.item_page'),
    }
    const computedFooterProps = computed(() => footerProps)

    const statusOptions = computed(() => [
      { text: t('status.paid'), value: 'P' },
      { text: t('status.pending'), value: 'N' },
      { text: t('status.cancelled'), value: 'C' },
      { text: t('status.free'), value: 'F' },
    ])

    const groupsOptions = ref([])

    const actions = ['Delete', 'Edit', 'Send']

    const actionOptions = [
      { title: 'Download', icon: mdiDownloadOutline },
      { title: 'Edit', icon: mdiPencilOutline },
      { title: 'Delete', icon: mdiDeleteOutline },
      { title: 'Duplicate', icon: mdiContentCopy },
    ]

    const checkType = data => {
      if (typeof data === 'number') return 'number'
      if (typeof data === 'string') return 'string'

      return 0
    }

    const selectTransaction = (item, all = false, val = false) => {
      if (!all) {
        const index = transactionSelected.value.findIndex(e => e === item.id)

        if (item.paid && index === -1) {
          transactionSelected.value.push(item.id)
          transactionSelectedItem.value.push(item)
          amountTransactionSelected.value += item.amount
        } else if (!item.paid && index !== -1) {
          transactionSelected.value.splice(index, 1)
          transactionSelectedItem.value.splice(index, 1)
          amountTransactionSelected.value -= item.amount
        }
      } else if (val) {
        const mapItems = transactionListTable.value.filter(e => !e.has_paid)
        transactionSelectedItem.value = mapItems
        transactionSelected.value = mapItems.map(e => e.id)
        amountTransactionSelected.value = mapItems.reduce((amount, curr) => amount + curr.amount, 0)

        transactionListTable.value.forEach(i => {
          if (!i.has_paid) i.paid = true
        })
      } else {
        transactionSelected.value = []
        transactionSelectedItem.value = []
        amountTransactionSelected.value = 0
        transactionListTable.value.forEach(i => {
          if (!i.has_paid) i.paid = false
        })
      }
    }

    const validSelectedTransactions = () => {
      if (transactionSelectedItem.value.length) {
        groupId.value = transactionSelectedItem.value[0].group_id

        return transactionSelectedItem.value.every(e => groupId.value === e.group_id)
      }

      return false
    }

    const addPayment = () => {
      if (validSelectedTransactions()) isAddPaymentSidebarActive.value = true
      else error('Las transacciones a pagar deben ser del mismo grupo')
    }

    const cancelLabels = computed(() => [
      { text: t('transactions.cancel_order'), value: 'title' },
      { text: t('transactions.refund'), value: 'refund' },
      { text: t('transactions.inactivate_video'), value: 'inactivate' },
      { text: t('transactions.confirm_cancel'), value: 'confirm' },
    ])

    const cancelVideo = async transaction => {
      Swal.fire({
        title: 'Cancel Order',
        icon: 'warning',
        html: `
          <div class="form-check" style="text-align: start;">
            <input class="form-check-input" type="checkbox" value="" id="checkbox1">
            <label class="form-check-label" for="checkbox1">
              Refund if payment was made
            </label>
          </div>
          <div class="form-check" style="text-align: start;">
            <input class="form-check-input" type="checkbox" value="" id="checkbox2">
            <label class="form-check-label" for="checkbox2">
              Hidden video from mobile app
            </label>
          </div>
          <div class="form-check" style="text-align: start;">
            <input class="form-check-input" type="checkbox" value="" id="checkbox3">
            <label class="form-check-label" for="checkbox3">
              Count for min charge
            </label>
          </div>
        `,
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: 'No',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes, Cancel Order!',
        focusConfirm: false,
        className: 'alert-font-family',
        preConfirm: async () => {
          const refund = document.getElementById('checkbox1').checked ? 'Y' : 'N'
          const hv = document.getElementById('checkbox2').checked ? 'Y' : 'N'
          const countedMinCharge = document.getElementById('checkbox3').checked ? 'Y' : 'N'

          if (transaction.video) {
            const resp = await cancelVideoTransaction(transaction.video.id, {
              refund,
              hide_video: hv,
              counted_min_charge: countedMinCharge,
              username: userData.value.username,
              cancel_type: 'O',
            })
            if (resp.ok) {
              await fetchPagedTransactions()
              success(resp.message)
            } else error(resp.message.text)
          }
        },
      })
    }

    const onReload = async () => {
      await fetchPagedTransactions()
      clearTransactionSelected()
    }

    const onChangeGroup = val => {
      const groupFinded = groupsOptions.value.find(e => e.id === val)
      groupName.value = groupFinded.name
      groupLogo.value = groupFinded.logo
    }

    const onGenerateReport = async () => {
      if (form.value.validate()) {
        const [y, m] = monthlyReport.value.date.split('-')
        year.value = y
        month.value = m

        const resp = await getTransactionsMonthly(monthlyReport.value.group_id, y, m)
        if (resp.ok) {
          paymentDataMonthly.value = resp
          const [da, ti] = resp.report_date_creation.split(', ')
          paymentDataMonthly.value.date = da
          paymentDataMonthly.value.time = ti

          html2PdfReport.value.generatePdf()
        }
      }
    }

    const beforeDownload = () => {
      info('Generating PDF!')
    }

    const hasDownloaded = () => {
      success('PDF download successful!')
      paymentDataMonthly.value = initMonthlyData.value
    }

    onMounted(async () => {
      const { filterParams, pageParams } = route.value.params
      setOptions(pageParams)
      setFilters(filterParams)

      if (userData.value && userData.value.role === 'A') {
        const groups = await getGroups()
        groupsOptions.value = groups.data
      }

      const filtersStored = getFilterByModule('views-order-history-list')
      if (filtersStored.status) statusFilter.value = filtersStored.status
      if (filtersStored.dateFrom) dateFrom.value = filtersStored.dateFrom
      if (filtersStored.dateTo) dateTo.value = filtersStored.dateTo
      if (filtersStored.search) searchQuery.value = filtersStored.search
      if (userData.value && userData.value.role === 'A' && filtersStored.purchaseSite) purchaseSiteFilter.value = filtersStored.purchaseSite

      await fetchPagedTransactions()
    })

    return {
      searchQuery,
      statusOptions,
      statusFilter,
      purchaseSiteFilter,
      purchaseSites,
      filters,
      options,
      descSort,
      totalTransactionListTable,
      transactionListTable,
      loading,
      actions,
      groupsOptions,
      actionOptions,
      transactionSelected,
      transactionSelectedItem,
      amountTransactionSelected,
      userData,
      computedTableColumns,
      transactionSelectedLength,
      groupId,
      computedFooterProps,
      columnsExcel,
      columnsExcelCSV,
      configObjFacility,

      dateNow,
      downloadFlag,
      timerDownload,
      minDate,
      maxDate,
      dateFrom,
      dateTo,
      modalFrom,
      modalTo,
      isOpenModal,
      form,
      monthlyReport,
      modalYear,
      modalMonth,
      paymentDataMonthly,
      hasTransactionVideoMonthly,
      html2PdfReport,

      computedDateFrom,
      computedDateTo,
      computedPaymentDataMonthly,
      groupName,
      groupLogo,
      year,
      month,
      minDateMont,

      onReload,
      clearTransactionSelected,
      cancelLabels,

      // i18n
      t,

      checkType,
      avatarText,
      resolveClientAvatarVariant,
      fetchAllTransactions,
      selectTransaction,
      validSelectedTransactions,
      formatCurrency,
      addPayment,
      resolveStatusText,
      cancelVideo,
      exportExcel,
      hasPermission,
      setFilters,
      setOptions,
      updateFilter,
      required,
      onChangeGroup,
      onGenerateReport,
      beforeDownload,
      hasDownloaded,
      formatStdDate,

      // drawer
      isAddPaymentSidebarActive,

      icons: {
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiCurrencyUsd,
        mdiCheck,
        mdiClose,
        mdiMicrosoftExcel,
        mdiFileExcelOutline,
        mdiPlus,
        mdiPrinter,
        export: require('@/assets/images/svg/export.svg'),
      },
    }
  },
}
</script>
