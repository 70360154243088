/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/prefer-default-export */
/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */

import { ref, watch, computed } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { getTransactions, getTransactionsPaged } from '@api'
import fetchPaged from '@core/utils/fetchPaged'
import usePermissions from '@core/utils/usePermissions'
import useCryptoJs from '@core/utils/useCryptoJs'
import useSelectOptions from '@core/utils/useSelectOptions'
import useFilters from '@/@core/utils/useFilters'
import { isObjEmpty } from '@core/utils'

export default function useOrderHistoryList() {
  const { userData } = useCryptoJs()
  const transactionListTable = ref([])
  const { hasPermission } = usePermissions()
  const { configOrganization, configFacility, configObjFacility } = useSelectOptions()
  const { t } = useUtils()
  const { updateFilter, getFilterByModule } = useFilters()

  // { text: t('transactions.end_time').toUpperCase(), value: 'video.end_time', show: true },
  const tableColumns = computed(() => [
    {
      text: t('transactions.paid').toUpperCase(),
      align: 'start',
      value: 'paid',
      sortable: false,
      show: true,
    },
    {
      text: '#ID',
      align: 'start',
      value: 'id',
      show: true,
    },
    { text: t('transactions.date').toUpperCase(), value: 'transaction_date_short', show: true },
    { text: t('organizations.organization').toUpperCase(), value: 'group_name', show: true },
    { text: t('transactions.video_date').toUpperCase(), value: 'video.video_date', show: true },
    { text: t('facilities.facility').toUpperCase(), value: 'video.facility_name', show: true },
    { text: t('transactions.field').toUpperCase(), value: 'video.field_name', show: true },
    { text: t('transactions.start_time').toUpperCase(), value: 'video.start_time', show: true },
    { text: t('transactions.amount').toUpperCase(), value: 'subtotal', sortable: false, show: true },
    { text: t('status.status').toUpperCase(), value: 'status', show: true },
    {
      text: t('tooltip.actions').toUpperCase(),
      value: 'actions',
      align: 'center',
      sortable: false,
      show: hasPermission(4) || hasPermission(5),
    },
  ])
  const computedTableColumns = computed(() => tableColumns.value.filter(e => e.show))
  const filters = ref(null)
  const searchQuery = ref('')
  const hasOptionsFilter = ref(false)
  const options = ref({
    itemsPerPage: 10,
    sortBy: ['id'],
    sortDesc: [true],
  })
  const descSort = ref([])
  const totalTransactionListTable = ref(0)
  const loading = ref(false)
  const statusFilter = ref(null)
  const purchaseSiteFilter = ref('console')
  const timerSearch = ref(null)
  const flag = ref(true)

  const purchaseSites = computed(() => [
    { text: 'Web Console', value: 'console' },
    { text: 'App Fanaty.com', value: 'landing' },
  ])

  const dateFrom = ref(null)
  const dateTo = ref(null)

  const minDate = ref(
    new Date(+new Date() - 12096e5).toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )

  const maxDate = ref(
    new Date(new Date()).toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )

  const jsonFields = {
    [`${t('transactions.order').toUpperCase()} ID`]: 'id',
    [`${t('transactions.transaction_date').toUpperCase()}`]: {
      field: 'transaction_date',
      callback: value => new Date(value).toISOString().substring(0, 10),
    },
    [`${t('fields.hour').toUpperCase()}`]: {
      field: 'transaction_date',
      callback: value => new Date(value).toISOString().substring(11, 16),
    },
    [`${t('transactions.order_by').toUpperCase()}`]: 'orderby',
    [`${t('transactions.amount').toUpperCase()}`]: 'amount',
    [`${t('videos.video').toUpperCase()} ID`]: 'video_id',
  }

  const columnsExcelCSV = computed(() => jsonFields)

  const columnsExcel = computed(() => [
    {
      label: `${t('transactions.order').toUpperCase()} ID`,
      field: 'id',
    },
    {
      label: t('transactions.transaction_date').toUpperCase(),
      field: 'transaction_date',
      dataFormat: value => new Date(value).toISOString().substring(0, 10),
    },
    {
      label: t('fields.hour').toUpperCase(),
      field: 'transaction_date',
      dataFormat: value => new Date(value).toISOString().substring(11, 16),
    },
    {
      label: t('transactions.order_by').toUpperCase(),
      field: 'orderby',
    },
    {
      label: t('transactions.amount').toUpperCase(),
      field: 'amount',
    },
    {
      label: `${t('videos.video').toUpperCase()} ID`,
      field: 'video_id',
    },
  ])

  const transactionSelected = ref([])
  const transactionSelectedItem = ref([])
  const amountTransactionSelected = ref(0)
  const transactionSelectedLength = computed(() => transactionSelected.value.length)

  const clearTransactionSelected = () => {
    transactionSelected.value.splice(0, transactionSelected.value.length)
    transactionSelectedItem.value.splice(0, transactionSelectedItem.value.length)
    amountTransactionSelected.value = 0

    transactionListTable.value.forEach(i => {
      if (i.status === 'N' && i.paid) {
        i.paid = false
        i.has_paid = false
      }
    })
  }

  const fetchAllTransactions = async () => {
    const response = await getTransactions()
    if (response.ok) return response.data

    return []
  }

  const fetchPagedTransactions = async () => {
    flag.value = true
    const filterOption = {}
    filters.value = null
    if (searchQuery.value) filterOption.search = searchQuery.value
    if (statusFilter.value) filterOption.status = statusFilter.value

    if (userData.value && userData.value.role !== 'A') {
      filterOption.group_id = userData.value.group_id
    } else if (configOrganization.value) {
      filterOption.group_id = configOrganization.value
    } else {
      filterOption.group_id = null
    }
    if (configFacility.value) filterOption.facility_id = configFacility.value
    if (dateFrom.value) filterOption.start_date = dateFrom.value
    if (dateTo.value) filterOption.end_date = dateTo.value
    if (userData.value && userData.value.role === 'A' && purchaseSiteFilter.value) filterOption.purchase_site = purchaseSiteFilter.value
    filters.value = filterOption

    const response = await fetchPaged(getTransactionsPaged, options.value, filterOption)
    transactionListTable.value = response.data
    totalTransactionListTable.value = response.total
    loading.value = false
  }

  const setOptions = value => {
    flag.value = false
    if (value) {
      hasOptionsFilter.value = true
      options.value = value
    } else {
      hasOptionsFilter.value = false
    }
  }

  const setFilters = value => {
    flag.value = false
    if (value && !isObjEmpty(value)) {
      if (value.search) searchQuery.value = value.search
      if (value.status) statusFilter.value = value.status
      if (value.start_date) dateFrom.value = value.start_date
      if (value.end_date) dateTo.value = value.end_date
    }
  }

  const getDataBySearch = async (time = 1000) => {
    clearTimeout(timerSearch.value)
    timerSearch.value = setTimeout(async () => {
      updateFilter('views-order-history-list', 'search', searchQuery.value)
      await fetchPagedTransactions()
    }, time)
  }

  watch([options], async () => {
    if (flag.value) {
      loading.value = true
      await getDataBySearch(300)
    }
  })

  watch([configFacility, configOrganization], async () => {
    searchQuery.value = ''
    statusFilter.value = null
    dateFrom.value = null
    dateTo.value = null
  })

  watch([statusFilter, configOrganization, configFacility, dateFrom, dateTo, purchaseSiteFilter], async () => {
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter) options.value.page = 1
    clearTransactionSelected()
    await getDataBySearch(500)
  })

  watch([searchQuery], async () => {
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter) options.value.page = 1
    await getDataBySearch()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveClientAvatarVariant = status => {
    if (status === 'Partial Payment') return 'primary'
    if (status === 'Paid' || status === 'N') return 'error'
    if (status === 'Downloaded') return 'secondary'
    if (status === 'C') return 'warning'
    if (status === 'F') return 'info'
    if (status === 'P') return 'success'

    return 'primary'
  }

  const resolveColor = status => {
    if (status === 'N') return '#FF4C51'
    if (status === 'C') return '#FFB400'
    if (status === 'F') return '#16B1FF'
    if (status === 'P') return '#56CA00'

    return '#333333'
  }

  const resolveStatusText = status => {
    if (status === 'A') return t('status.active')
    if (status === 'I') return t('status.inactive')
    if (status === 'P') return t('status.paid')
    if (status === 'N') return t('status.pending')
    if (status === 'F') return t('status.free')
    if (status === 'C') return t('status.cancelled')

    return t('status.active')
  }

  return {
    searchQuery,
    filters,
    options,
    transactionListTable,
    statusFilter,
    totalTransactionListTable,
    userData,
    loading,
    computedTableColumns,
    columnsExcel,
    columnsExcelCSV,
    minDate,
    maxDate,
    dateFrom,
    dateTo,
    transactionSelected,
    transactionSelectedItem,
    amountTransactionSelected,
    transactionSelectedLength,
    hasOptionsFilter,
    purchaseSiteFilter,
    purchaseSites,
    configObjFacility,

    // paged
    descSort,

    // i18n
    t,

    getDataBySearch,
    fetchPagedTransactions,
    fetchAllTransactions,
    resolveClientAvatarVariant,
    resolveStatusText,
    resolveColor,
    clearTransactionSelected,
    hasPermission,
    setFilters,
    setOptions,
    updateFilter,
    getFilterByModule,
  }
}
